import BasicLayout from "@/components/v2/templates/BasicLayout/BasicLayout";
import { Images } from "@/images/all";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { trackAppDownload } from "static/lib/tracking";
import { isAndroid, isIOS, openAppStorePage, openPlayStorePage } from "../static/lib/util";

export default function app() {
  const router = useRouter();
  const { t } = useTranslation("order");

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isIOS()) {
        openAppStorePage();
      } else if (isAndroid()) {
        openPlayStorePage();
      } else {
        router?.push("/");
      }
    }
  }, []);

  return (
    <div
      className="bg-black text-center sm:h-full flex flex-col justify-between text-white py-7 px-4 sm:p-6 sm:pb-4 relative shadow-xl"
      id="type-selector-modal"
    >
      <div className="mt-12 text-white font-medium text-sm text-left">
        <h3 className="text-xl font-bold">{t("get_more_crumbl_app")}</h3>
        <p>{t("get_more_crumbl_app_text")}</p>

        <div className="pt-10 relative">
          <Image src={Images.appArrow} alt="" width={57} height={52} className="absolute right-4 top-0" />
          <Image
            onClick={() => {
              if (isIOS()) {
                trackAppDownload("IOS");
                openAppStorePage();
              } else if (isAndroid()) {
                trackAppDownload("Android");
                openPlayStorePage();
              } else {
                openAppStorePage();
              }
            }}
            src={Images.pinkAppScreenshot}
            alt={t("crumbl_app")}
            className="mt-10 mx-auto"
            width={209}
            height={214}
          />
        </div>
      </div>
    </div>
  );
}

export async function getStaticProps({ locale = "en" }) {
  const translations = await serverSideTranslations(locale, ["common", "order"]);

  return {
    props: { ...translations },
  };
}

app.getLayout = (a) => <BasicLayout>{a}</BasicLayout>;
